.banner img {
  max-width: 100%;
}

.wrapper_main {
  max-width: 780px;
  padding: 0 15px; // Убрано смещение слева
  margin: 76px auto; // Центровка по горизонтали
}

.wrapper_main h2 {
  font-size: 42px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 48px;
}

.container {
  display: flex;
  justify-content: center; // Центровка контейнера
  margin-bottom: 28px;
  transition: all 0.5s;
  padding: 10px;
  border-radius: 7px;
  margin: auto;
  max-width: 1100px;
}

.contentGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; // Центровка блоков внутри контейнера
}

.testBlock, .articleBlock {
  flex: 1 1 calc(33.333% - 20px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  transition: all 0.5s;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  }
}

.wrapper_img {
  max-width: 229px;
  width: 100%;
  img {
    border-radius: 7px;
    width: 100%;
  }
}

.wrapper_right_block {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-left: 10px;
  text-align: left;
  color: #000;
}

.headline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  h4 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
  }
  span {
    font-weight: 400;
    white-space: nowrap;
    padding: 0 5px;
  }
}

.text {
  color: #929292;
  font-size: 14px;
  line-height: 141.9%;
  font-weight: 400;
}

.date {
  align-self: flex-start;
  margin-top: auto;
}

.date p {
  color: #929292;
  font-size: 14px;
  font-weight: 400;
}

.date span {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 500;
  margin-left: 7px;
}

.date span:global.active {
  color: #89b126;
}

@media (max-width: 1100px) {
  .wrapper_main {
    padding: 0 10px; // Уменьшено внутреннее отступление
    margin-top: 12px;
  }
  
  .wrapper_main h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .headline h4 {
    font-size: 22px;
  }

  .contentGrid {
    gap: 10px;
  }

  .testBlock, .articleBlock {
    flex: 1 1 calc(50% - 10px);
  }

  .wrapper_img {
    max-width: 150px;
    padding-right: 20px;
  }

  .wrapper_right_block {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    text-align: left;
    color: #000;
  }

  .headline {
    flex-direction: column;
    align-items: flex-start;
  }

  .text {
    font-size: 0px;
  }

  .date p {
    font-size: 16px;
  }

  .date span {
    font-size: 14px;
  }

  .date {
    align-self: flex-start;
    margin-top: auto;
  }

  .date span:global.active {
    color: #89b126;
  }
}
