header {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 100;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}

header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  border-right: 1px solid #929292;
  padding-right: 15px;
  margin-left: 10px;
  width: 300px;
}

.logo p {
  padding-left: 7px;
}

nav {
  display: flex;
  align-items: center;
}

.profile, .search {
  display:flex;
  float: right;
}

.profile button,
.profile a, 
.search button,
.search a {
  background-color: #fff;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 14px;
  transition: all 0.5s;
}

.profile button:hover,
.profile a:hover,
.search button:hover,
.search a:hover {
  opacity: 0.6;
}
.burger {
  display: none;
}

@media (max-width: 1100px) {
  .burger {
    cursor: pointer;
    width: 30px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;  
    z-index: 99;
  }
  
  .burger span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: black;
    // transition: all 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform-origin: center;
  }
  
  .burger.open span:nth-child(1) {
    transform: rotate(45deg) translate(0, 11.5px);
  }
  
  .burger.open span:nth-child(2) {
    opacity: 0;
    visibility: hidden;
  }
  
  .burger.open span:nth-child(3) {
    transform: rotate(-45deg) translate(0, -11.5px);
  }

  .profile {
    position: absolute;
    right: 10px; 
    top: 35px; 
  }

  .nav {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    background: white;
    visibility: hidden;
    opacity: 0;
    flex-direction: column;
    align-items: center;
    transition: visibility 0s linear 0.3s, opacity 0.3s linear, transform 0.3s ease-in-out;
    transform: translateY(-100%);
    width: 100%;
    z-index: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .nav.open {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0s; 
    padding-bottom: 5px;
  }

  .nav:not(.nav.open) {
    transform: translateY(-100%);
  }

  .nav a {
    padding: 10px;
    width: 100%;
    text-align: center;
  }

  .logo, .logo p {
    position: static;
    transform: none;
    display: block;
    text-align: center;
    width: 100%;
  }
  .logo img {
    border-right: 0px solid #929292;
    width: 250px;
  }
}