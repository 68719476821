.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
  transition: all 0.5s;
  padding: 10px;
  border-radius: 7px;
  margin: auto;
  max-width: 1100px;
}
.wrapper {
  max-width: 875px;
  // padding-left: 80px;
  // margin-top: 76px;
  width: 100%;
}
.testBlock {
  display: flex;
  margin-bottom: 28px;
  transition: all 0.5s;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  //box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  box-shadow: 3px 7px 30px 0px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  }
}

.wrapper_img {
  max-width: 229px;
  width: 100%;
  img {
    border-radius: 7px;
    width: 100%;
  }
}

.wrapper_right_block {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-left: 38px;
  text-align: left;
  color: #000;
}

.headline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  h4 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
  }
  span {
    font-weight: 400;
    white-space: nowrap;
    padding: 0 5px;
  }
}

.text {
  color: #929292;
  font-size: 14px;
  line-height: 141.9%;
  font-weight: 400;
}
.date {
  // align-self: flex-end;
  margin-top: auto;
  float: left;
}
.date p {
  color: #929292;
  font-size: 14px;
  font-weight: 400;
}
.date span {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 500;
  margin-left: 7px;
}
.date span:global.active {
  color: #89b126;
}
@media (max-width: 1100px) {
  .wrapper_img {
    max-width: 150px;
    width: 100%;
    img {
      border-radius: 7px;
      width: 100%;
    }
  }
}