.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    h1 {
        font-size: 24px;
        color: #333;
        margin-bottom: 20px;
        text-align: center;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .createButton, .exportButton {
        background-color: #1AB394;
        color: white;
        border: none;
        padding: 9px 15px;
        border-radius: 2px;
        cursor: pointer;
        transition: all 0.3s;
        width: 200px;
    }

    .createButton:active, .exportButton:active {
        background-color: #0AA384;
        box-shadow: 0 5px #666;
        transform: translateY(4px);
    }

    .createButton:hover, .exportButton:hover {
        background-color: #0AA384;
    }

    .createButton:focus, .exportButton:focus {
        outline: none;
        border-color: #0AA384;
        box-shadow: 0 0 10px #0AA384;
    }

    .filters {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
    }

    .filter {
        display: flex;
        flex-direction: column;
    }

    .reportTable {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
    }

    .reportTable th, .reportTable td {
        border: 1px solid #ddd;
        padding: 8px;
    }

    .reportTable th {
        background-color: #0AA384;
        cursor: pointer;
    }

    .reportRow:hover {
        background-color: #f1f1f1;
    }
}

/* Адаптация для мобильных устройств */
@media (max-width: 768px) {
    .container {
        padding: 15px;
    }

    h1 {
        font-size: 20px; /* Уменьшаем размер заголовка */
    }

    .header {
        // flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    // .createButton, .exportButton {
    //     width: 100%; /* Кнопки занимают всю ширину экрана на мобильных устройствах */
    // }

    .filters {
        flex-direction: column; /* Вертикальное расположение фильтров */
        gap: 10px;
    }

    .reportTable th, .reportTable td {
        font-size: 14px; /* Уменьшаем размер текста в таблице */
        padding: 6px;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 10px;
    }

    h1 {
        font-size: 18px; /* Ещё меньше для маленьких экранов */
    }

    .filters {
        gap: 8px;
    }

    .createButton, .exportButton {
        width: 100%; /* Ширина кнопок 100% для маленьких экранов */
        padding: 10px; /* Увеличиваем padding для удобства */
    }

    .reportTable th, .reportTable td {
        font-size: 12px; /* Ещё меньше размер текста в таблице */
        padding: 5px; /* Уменьшаем отступы в ячейках */
    }
}
