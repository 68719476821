nav a {
  margin-right: 20px;
  color: #000;
  transition: all 0.5s;
  position: relative;
}
nav > a:hover {
  opacity: 0.5;
}
nav > a::before {
  content: '';
  position: absolute;
  bottom: -4px;
  width: 0;
  height: 1px;
  display: block;
  background-color: #000;
  transition: all 0.3s;
}
nav > a:hover::before {
  width: 100%;
}

.active::before {
  width: 100%;
}
