.container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    overflow-x: hidden; /* Убираем горизонтальный скролл */
}

.heading {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.tableWrapper {
    width: 100%;
    overflow-x: auto; /* Включаем автоматическую прокрутку только для таблицы, если она выходит за пределы */
    -webkit-overflow-scrolling: touch; /* Поддержка плавной прокрутки на мобильных устройствах */
}

.reportTable {
    width: 100%;
    table-layout: fixed; /* Добавляем фиксированную ширину столбцов, чтобы контент не ломал таблицу */
    border-collapse: collapse;
}

.reportTable th, .reportTable td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    word-wrap: break-word; /* Позволяем содержимому переноситься на новую строку */
}

.reportTable th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.label {
    font-weight: bold;
}

.input, .select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.loading {
    font-size: 14px;
    color: #777;
}

.submitButton {
    background-color: #000;
    color: white;
    border-radius: 4px;
    border: none;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin-top: 20px;

    &:hover {
        background-color: #0aa384;
    }
}

/* Мобильные стили */
@media (max-width: 768px) {
    .container {
        padding: 15px;
    }

    .heading {
        font-size: 20px;
    }

    .input, .select {
        padding: 8px;
        font-size: 14px;
    }

    .submitButton {
        padding: 12px;
    }

    .reportTable th, .reportTable td {
        padding: 8px;
    }
    .fileChange {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 10px;
    }

    .heading {
        font-size: 18px;
    }

    .input, .select {
        font-size: 12px;
        padding: 6px;
    }

    .submitButton {
        padding: 10px;
    }

    .reportTable th, .reportTable td {
        font-size: 12px;
        padding: 6px;
    }
    .fileChange {
        width: 100%;
    }
}
