.footer {
    background-color: #f8f8f8;
    padding: 15px 0;
    text-align: center;
    font-size: 14px;
    color: #666;
    border-top: 1px solid #e7e7e7;
    // position: absolute;
    width: 100%;
    bottom: 0;
}

.footer p {
    margin: 0;
}
