.root {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  .profileH2 {
    font-size: 42px;
    font-weight: 500;
    line-height: 106.023%;
    text-align: center;
  }
}
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .test {
    width: 693px;
  }
  .sidebar {
    border-radius: 8px;
    background: #fff;
    box-shadow: 3px 7px 30px 0px rgba(0, 0, 0, 0.1);
    width: 330px;
    padding: 39px 25px;
    .aside {
        h4 {
          font-size: 20px;
          font-weight: 500;
          line-height: 106.023%;
          text-align: center;
        }
        p {
          text-align: center;
          padding: 10px;
          //border-bottom: 1px solid #000;
          margin-bottom: 16px;
        }
    }
    .photo {
      border-radius: 50%;
      width: 246px;
      height: 246px;
      margin: 0 auto 52px;
      background-color: #d9d9d9;
    }
  }
  .edit {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1100px) {

  .root {
    margin-top: 20px;
  }
  .profileH2 {
    text-align: center;
    font-size: 24px; 
  }
  .wrapper {
    flex-direction: column;
    width: 100%;

    .sidebar{
      width: 100%;
      margin-bottom: 20px;
    }
    .test {
      width: 100%;
    }
  }
}