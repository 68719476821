.wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto; /* Центрирование блока */
}

/* Главный контейнер, содержащий элементы */
.container {
  display: flex;
  flex-direction: column; /* Для вертикального выравнивания блоков */
  margin-bottom: 10px;
  transition: all 0.5s;
  padding: 10px;
  border-radius: 7px;
  margin: auto; /* Автоматические отступы для центрирования */
  max-width: 100%; /* Максимальная ширина */
}

/* Блоки внутри контейнера */
.testBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  transition: all 0.5s;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
}

.testBlock:hover {
  box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
}

.userDetails {
  display: flex;
  //justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.userDetails h4 {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.userDetails span {
  font-weight: 400;
  white-space: nowrap;
  padding: 0 5px;
  text-align: center;
  margin-left: auto;
}

.roleSelect {
  margin-left: auto;
  float: right;
}

.testInfo {
  color: #929292;
  font-size: 14px;
  line-height: 141.9%;
  font-weight: 400;
}

.wrapper_right_block {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-left: 38px;
  text-align: left;
  color: #000;
}

.headline {
  margin-bottom: 15px;
  width: 100%;
}

.headline h4 {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.headline span {
  font-weight: 400;
  white-space: nowrap;
  padding: 0 5px;
}

.text {
  color: #929292;
  font-size: 14px;
  line-height: 141.9%;
  font-weight: 400;
}

.date {
  margin-top: auto;
  float: none;
}

.date p {
  color: #929292;
  font-size: 14px;
  font-weight: 400;
}

.date span {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 500;
  margin-left: 7px;
}

.date span:global.active {
  color: #89b126;
}
.exportButton {
  background-color: #1AB394;
  color: white;
  border: none;
  padding: 9px 15px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s; 
  // float: right;
  width: 200px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
}
.exportButton:hover {
  background-color: #0AA384;
}
.exportButton:active {
  background-color: #0AA384;
  box-shadow: 0 5px #666;
  transform: translateY(4px); 
}
.exportButton:focus {
  outline: none;             
  border-color: #0AA384;     
  box-shadow: 0 0 10px #0AA384;
}
.deleteButton {
  background-color: #1AB394;
  color: white;
  border: none;
  // padding: 9px 15px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s; 
  float: right;
  width: 200px;
  margin-left: 10px;
  // margin-top: 10px;
}
.deleteButton:hover {
  background-color: #0AA384;
}
.deleteButton:active {
  background-color: #0AA384;
  box-shadow: 0 5px #666;
  transform: translateY(4px); 
}
.deleteButton:focus {
  outline: none;             
  border-color: #0AA384;     
  box-shadow: 0 0 10px #0AA384;
}
.resetButton {
  background-color: #1AB394;
  color: white;
  border: none;
  padding: 9px 15px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s; 
  float: left;
  width: 200px;
  margin-top: 10px;
  // margin: 0 30px;
}
.resetButton:hover {
  background-color: #0AA384;
}
.resetButton:active {
  background-color: #0AA384;
  box-shadow: 0 5px #666;
  transform: translateY(4px); 
}
.resetButton:focus {
  outline: none;             
  border-color: #0AA384;     
  box-shadow: 0 0 10px #0AA384;
}
.roleSpan {
  text-align: center;
}

@media (max-width: 1100px) {
  .container {
      padding: 5px;
  }

  .testBlock {
      flex-direction: column;
      margin-bottom: 15px;
  }

  .userDetails {
      flex-direction: column;
      align-items: flex-start;
  }

  .headline h4 {
      font-size: 18px;
      line-height: 18px;
  }

  .headline span {
      font-size: 14px;
  }

  .date p {
      font-size: 12px;
  }

  .date span {
      font-size: 14px;
  }

  .resetButton {
      padding: 8px 16px;
      font-size: 12px;
  }
  .deleteButton {
    padding: 8px 16px;
    font-size: 12px;
  }
  .roleSpan {
    // display: none;
    margin-bottom: 30px;
  }
}
