.container {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 28px;
  transition: all 0.5s;
  padding: 10px;
  border-radius: 7px;
  margin: auto;
  max-width: 1100px;
}
.wrapper {
  max-width: 875px;
  padding-left: 80px;
  margin-top: 76px;
  width: 100%;
}

.testBlock {
  display: flex;
  margin-bottom: 28px;
  transition: all 0.5s;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  }
}

.wrapper_img {
  max-width: 229px;
  width: 100%;
  img {
    border-radius: 7px;
    width: 100%;
  }
}

.wrapper_right_block {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-left: 38px;
  text-align: left;
  color: #000;
}

.headline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  h4 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
  }
  span {
    font-weight: 400;
    white-space: nowrap;
    padding: 0 5px;
  }
}

.text {
  color: #929292;
  font-size: 14px;
  line-height: 141.9%;
  font-weight: 400;
}
.date {
  align-self: flex-start;
  margin-top: auto;
}
.date p {
  color: #929292;
  font-size: 14px;
  font-weight: 400;
}
.date span {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 500;
  margin-left: 7px;
}
.date span:global.active {
  color: #89b126;
}
.addButton {
  background-color: #1AB394;
  color: white;
  border: none;
  padding: 9px 15px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s; 
  float: right;
  width: 200px;
  // margin-left: 10px;
  margin-top: -90px;
}
.addButton:active {
  background-color: #0AA384;
  box-shadow: 0 5px #666;
  transform: translateY(4px); 
}
.addButton:hover {
  background-color: #0AA384;
}
.addButton:focus {
  outline: none;             
  border-color: #0AA384;     
  box-shadow: 0 0 10px #0AA384;
}
.ul {
  list-style: none;
  padding: 0;
  padding-top: 160px;
  width: 100%;
}

.li {
  cursor: pointer;
  margin: 10px 0;
  margin-bottom: 5px;
  margin-right: 20px;
  color: #000;
  transition: color 0.5s ease;
  position: relative;
  display: block;
  width: fit-content;
}

.li:hover {
  opacity: 0.5;
}

.li::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 1px;
  background-color: #000;
  transform: scaleX(0); 
  transform-origin: left;
  transition: transform 0.3s ease;
}
.active {
  font-weight: bold;
}

.active::before {
  transform: scaleX(1);
}
.categories {
  flex: 1;
  margin-right: 20px;
  margin: 10px;
  margin-left: 10px;
  
}
@media (max-width: 1100px) {
  .addButton {
    display:none;
  }
  .testH2 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 24px;
  }
  .container {
    flex-direction: row; 
    padding: 10px;
    // max-width: 100%;
    //margin: 10px auto; 
  }
  .wrapper {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #000;
    margin-top: 12px;
  }
  .testBlock {
    flex-direction: row;
    padding: 10px;
    align-items: flex-start;
  }
  .wrapper_img {
    max-width: 150px;
    padding-right: 20px
  }
  .wrapper_right_block {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    text-align: left;
    color: #000;
    flex-grow: 1;
    height: 100%;
  }
  .headline {
    flex-direction: column; 
    align-items: flex-start; 
  }
  .headline h4 {
    font-size: 22px; 
  }
  .text{
    font-size: 0px;
  } 
  .date {
    align-self: flex-start;
    margin-top: auto;
  }
  .date p {
    font-size: 16px;
  } 
  .date span {
    font-size: 14px; 
  }

  .date span:global.active {
    color: #89b126;
  }
  .container {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
    overflow-x: hidden;
    flex-direction: column;
  }

  .categories {
    margin: 0 auto 10px auto;
  }
  .li {
    margin: 5px 0;
    font-size: 14px;
  }

  .ul {
    padding-top: 10px;
  }
}
