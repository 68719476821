.container {
    max-width: 800px; /* Ограничиваем ширину контейнера */
    margin: 20px auto; /* Центрируем контейнер */
    padding: 20px; /* Внутренние отступы */
    border-radius: 8px; /* Скругленные углы */
    background-color: #f9f9f9; /* Светлый фон */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Тень для глубины */
  
    h1 {
        font-size: 24px; /* Размер заголовка */
        color: #333; /* Цвет заголовка */
        margin-bottom: 20px; /* Отступ снизу */
        text-align: center; /* Центрируем текст */
    }
    
    .reportTable {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
    }
    
    .reportTable th, .reportTable td {
        border: 1px solid #ddd;
        padding: 8px;
    }
    
    .reportTable th {
        background-color: #0AA384;
        text-align: left;
    }
    
    .reportImage {
        width: 200px;
        height: auto;
        cursor: pointer;
        transition: transform 0.3s ease;
    }
    
    .reportImage:hover {
        transform: scale(1.1);
    }
    
    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }
    
    .modalContent {
        max-width: 120%;
        max-height: 120%;
    }
    
    .close {
        position: absolute;
        top: 20px;
        right: 35px;
        color: white;
        font-size: 40px;
        font-weight: bold;
        cursor: pointer;
    }

    .editButton {
        background-color: #1AB394;
        color: white;
        border: none;
        padding: 9px 15px;
        border-radius: 2px;
        cursor: pointer;
        transition: all 0.3s; 
        width: 200px;
    }

    .editButton:active {
        background-color: #0AA384;
        box-shadow: 0 5px #666;
        transform: translateY(4px); 
    }

    .editButton:hover {
        background-color: #0AA384;
    }

    .editButton:focus {
        outline: none;             
        border-color: #0AA384;     
        box-shadow: 0 0 10px #0AA384;
    }
}

/* Адаптация для мобильных устройств */
@media (max-width: 768px) {
    .container {
        padding: 15px;
    }

    h1 {
        font-size: 20px; /* Уменьшаем размер заголовка */
    }

    .reportTable th, .reportTable td {
        font-size: 14px; /* Уменьшаем размер текста в таблице */
        padding: 6px;
    }

    .reportImage {
        width: 150px; /* Уменьшаем размер изображений на мобильных устройствах */
    }

    .editButton {
        width: 100%; /* Кнопка занимает всю ширину экрана на мобильных устройствах */
    }
}

@media (max-width: 480px) {
    .container {
        padding: 10px;
    }

    h1 {
        font-size: 18px; /* Ещё меньше для маленьких экранов */
    }

    .reportTable th, .reportTable td {
        font-size: 12px; /* Ещё меньше размер текста */
        padding: 5px;
    }

    .reportImage {
        width: 100px; /* Ещё меньше изображение на очень маленьких экранах */
    }

    .editButton {
        padding: 12px; /* Увеличиваем padding для удобства */
        width: 100%; /* Кнопка занимает всю ширину */
    }

    .modalContent {
        max-width: 100%; /* Уменьшаем ширину модального окна для маленьких экранов */
        max-height: 100%; /* Уменьшаем высоту модального окна */
    }

    .close {
        top: 10px; /* Уменьшаем отступы для маленьких экранов */
        right: 20px;
        font-size: 30px; /* Уменьшаем размер иконки закрытия */
    }
}
