.popap_search {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  transition: transform 0.5s, opacity 0.5s;
  transform: translateY(-100%);
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's above other elements */
  padding: 20px; /* Add some padding for better visuals */
}

.block_search {
  width: 80%; /* Adjust the width as needed */
  display: flex;
}

.block_search input {
  flex-grow: 1;
  outline: none;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px; /* Increase font size for better readability */
}

.block_search button {
  padding: 10px;
  border: 1px solid #000;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  font-size: 16px; /* Increase font size for better readability */
}

.activePopap {
  transform: translateY(0);
  opacity: 1;
}

.closeButton {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
}

.popap_results {
  width: 80%;
  max-height: 60%;
  overflow-y: auto;
  background-color: #fff;
  // border: 1px solid #ccc;
  margin-top: 20px;
}

.popap_results ul {
  list-style: none;
  padding: 0;
}

.popap_results li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.popap_results li a {
  text-decoration: none;
  color: #000;
  display: block;
}

.popap_results li a:hover {
  background-color: #f0f0f0;
}
