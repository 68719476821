.surveyContainer {
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  width: 1200px; /* Установленная ширина */
  margin: 0 auto; /* Центрирование блока */
  max-width: 100%; /* Для корректного отображения на узких экранах */
  padding-bottom: 40px;
  margin-top: 30px;
  
}

.sv-question {
  width: 100%; /* Адаптивная ширина для вопросов */
}

.editButton, .deleteButton {
  background-color: #1AB394;
  color: white;
  border: none;
  padding: 9px 15px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  float: right;
  margin: 0 30px;
  margin-top: 3px;
}

.editButton:hover, .deleteButton:hover {
  background-color: #0AA384;
}

.editButton:active, .deleteButton:active {
  background-color: #0AA384;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.editButton:focus, .deleteButton:focus {
  outline: none;
  border-color: #0AA384;
  box-shadow: 0 0 10px #0AA384;
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 900px) {
  .surveyContainer {
    width: 95%; /* Меньшая ширина для удобства на мобильных устройствах */
    margin: 0 auto; /* Центрирование блока */
    text-align: left;
  }

  .editButton, .deleteButton {
    float: none; /* Убираем обтекание для кнопок */
    display: block; /* Блочное отображение */
    width: 100%; /* Полная ширина кнопок */
    margin: 10px 0; /* Отступы сверху и снизу */
  }
}
